import React, { FC } from "react";
import { useModalStyles } from "./modal.style";
import { ReactComponent as CloseIcon } from "@assets/images/close_icon.svg";
import { isMobile } from "@utils/isMobile.utils";
import { ReactComponent as Logo } from "@assets/images/icon-login-header.svg";
import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";

interface ModalProps {
  handleClickCloseModal?: () => void;
  title: string;
  description?: string;
  subDescription?: string;
  isLogo?: boolean;
  isMail?: boolean;
  children?: React.ReactNode;
}

const Modal: FC<ModalProps> = (props) => {
  const {
    handleClickCloseModal,
    title,
    description,
    subDescription,
    isLogo,
    isMail,
    children,
  } = props;
  const classes = useModalStyles();
  const isMobiles = isMobile();

  return (
    <Dialog
      open={true}
      onClose={handleClickCloseModal}
      fullWidth
      maxWidth={isMobiles ? "sm" : "md"}
      classes={{
        paper: classes.dialogPaper,
        root: classes.root,
        container: classes.container,
      }}
    >
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent={isMail ? "right" : "space-between"}
        >
          <IconButton onClick={handleClickCloseModal}>
            <CloseIcon
              width={isMobiles ? "20" : "30"}
              height={isMobiles ? "20" : "30"}
            />
          </IconButton>
        </Grid>
        <Grid
          item
          style={{
            textAlign: "center",
            padding: isMobiles ? "0px 0px 10px" : "0px 50px 50px",
          }}
        >
          {isLogo && (
            <Logo
              style={{ marginBottom: "20px" }}
              width={isMobiles ? "50" : "100"}
              height={isMobiles ? "50" : "100"}
            />
          )}
          <Typography
            variant={isMobiles ? "h2" : "h1"}
            fontWeight={isMail ? 300 : 700}
            color={isMail ? "inherit" : "primary"}
            style={{ marginTop: "10px" }}
          >
            {title}
          </Typography>
          <Typography
            fontSize={isMobiles ? 14 : 16}
            fontWeight={400}
            style={{ margin: isMail ? "30px 0px" : "30px 0 0 0" }}
          >
            {description}
          </Typography>
          <Typography
            fontSize={isMobiles ? 14 : 16}
            fontWeight={400}
            style={{ margin: "14px 0px" }}
          >
            {subDescription}
          </Typography>
          {children}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
