import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { Colors } from "@style/materialColors";

const classes = (theme: Theme) =>
  createStyles({
    inputLabel: {
      color: "#262E3E !important",
      fontWeight: "700 !important",
      fontSize: "16px !important",
    },
  });

export default classes;
