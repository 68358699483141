import React, { useState, FormEvent, useEffect } from "react";
import DescriptionOfPage from "@components/titlePage/descriptionOfPage";
import { Grid } from "@mui/material";
import MaterialLoginTextfield from "@components/materialTextfield/materialLoginTextfield";
import kids from "@assets/images/header-kids.png";
import kidsMobile from "@assets/images/kidsMobile.jpg";
import { isMobile, isTablet } from "@utils/isMobile.utils";
import { codeboxService } from "@store/codebox";
import { codeboxQuery } from "@store/codebox/";
import { LoadingButton } from "@mui/lab";
import Modal from "@components/modal";
import GooglePlay from "@assets/images/google_play.png";
import PlayStore from "@assets/images/apple_store.png";
import { useCodeboxStyles } from "./codebox.style";
import { useHistory } from "react-router-dom";

const CodeboxScreen = () => {
  const [codeValue, setCodeValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSuccess, setIsCodeSuccess] = useState(false);
  const isMobiles = isMobile();
  const isTablets = isTablet();
  const classes = useCodeboxStyles();
  const history = useHistory();

  useEffect(() => {
    const _loading = codeboxQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _loading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setIsCodeSuccess(true);
    }
    history.push("/codebox");
    localStorage.removeItem("codeSuccess");
  }, []);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    codeboxService.sendCode(codeValue).then(() => {
      if (localStorage.getItem("codeSuccess") === "success") {
        history.push(`/codebox?success=true`);
        window.location.reload();
      }
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      rowSpacing={5}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    >
      {isMobiles ? (
        <img src={kidsMobile} width="100%" height="330px" />
      ) : (
        <img src={kids} width="100%" />
      )}
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Grid
          container
          item
          alignContent="center"
          flexDirection="column"
          rowSpacing={isMobiles ? 12 : 17}
          style={{ padding: "60px 40px" }}
        >
          <DescriptionOfPage
            logo={isMobiles ? false : true}
            title="J'ai un coffret"
            description="Entrez le code Kabriol que vous trouverez dans votre coffret pour débloquer le contenu de l'application!"
          />
          <Grid
            item
            style={{ margin: "auto", width: isTablets ? "100%" : "30%" }}
          >
            <MaterialLoginTextfield
              placeholderCenter
              type="text"
              placeholder="Entrez votre code ici"
              color="primary"
              value={codeValue}
              handleChange={setCodeValue}
            />
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              style={{ display: "flex", alignItems: "center" }}
            >
              Valider
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      {isCodeSuccess && (
        <Modal
          title="Félicitations, vous venez d’activer votre compte!"
          description="Rendez-vous dans l’application Kabriol pour commencer l’expérience"
          isLogo
          handleClickCloseModal={() => setIsCodeSuccess(false)}
        >
          <div style={{ marginTop: isMobiles ? "20px" : "30px" }}>
            <LoadingButton href="https://play.google.com/store/apps/details?id=com.kabriol.app">
              <img
                src={GooglePlay}
                alt="Logo Play Store"
                width={150}
                className={classes.imgStyle}
              />
            </LoadingButton>
            <LoadingButton href="https://apps.apple.com/fr/app/kabriol-en-voiture-les-enfants/id1568991322">
              <img
                src={PlayStore}
                alt="Logo App Store"
                width={150}
                className={classes.imgStyle}
              />
            </LoadingButton>
          </div>
        </Modal>
      )}
    </Grid>
  );
};

export default CodeboxScreen;
