import { loadStripe } from "@stripe/stripe-js";
import { apiUtils } from "@api/api.utils";

export class ApiStripe {
  getStripe = () => {
    let stripePromise: any;
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PROD!);
    }
    return stripePromise;
  };

  checkoutOptions = (selected: number) => {
    return {
      lineItems: [apiUtils.amountStripe(selected)],
      mode: "payment",
      successUrl: `${window.location.origin}/offer?${localStorage.getItem(
        "success"
      )}=true/`,
      cancelUrl: `${window.location.origin}/offer?${localStorage.getItem(
        "cancel"
      )}=true/`,
    };
  };

  redirectToCheckout = (selected: number) => {
    return async () => {
      apiUtils.sendItemsInLocalStorage(selected);
      const stripe = await this.getStripe();
      const { error } = await stripe.redirectToCheckout(
        this.checkoutOptions(selected)
      );
    };
  };
}

export const apiStripe = new ApiStripe();
