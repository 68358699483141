import React, { FC } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { apiUtils } from "@api/api.utils";
import { useHistory } from "react-router-dom";
import { displayAlert as toast } from "@utils/snackbar.utils";

interface ApiPayPalProps {
  selected: number;
  onClick: () => void;
}

const ButtonPaypal: FC<ApiPayPalProps> = (props) => {
  const { selected, onClick } = props;
  const history = useHistory();

  return (
    <PayPalButtons
      style={{
        color: "white",
        layout: "horizontal",
        height: 48,
        tagline: false,
        shape: "rect",
      }}
      onClick={onClick}
      createOrder={(_, actions) => {
        return actions.order.create({
          purchase_units: [apiUtils.amountPayPal(selected)],
        });
      }}
      onApprove={async (_, actions) => {
        const order = await actions.order?.capture();
        if (order?.status === "COMPLETED") {
          actions.redirect(
            `${window.location.origin}/offer?${localStorage.getItem(
              "success"
            )}=true/`
          );
        }
      }}
      onCancel={(_, actions) => {
        actions.redirect();
        toast.error(
          "Vous venez d'annuler le paiement, veuillez choisir un moyen de paiement.",
          {
            variant: "error",
          }
        );
      }}
      onError={() => {
        history.push(
          `${window.location.origin}/offer?${localStorage.getItem(
            "cancel"
          )}=true/`
        );
        window.location.reload();
      }}
    />
  );
};

export default ButtonPaypal;
