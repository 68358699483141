import { TextField } from "@mui/material";
import React, { FC } from "react";
import { ErrorOutline } from "@mui/icons-material";
import { useMaterialTextfieldBaseStyles } from "./MaterialTextfieldBase.style";

export interface MaterialTextfieldBaseProps {
  label?: string;
  fullWidth?: boolean;
  name?: string;
  required?: boolean;
  placeholder?: string;
  color?: "primary" | "secondary";
  pattern?: RegExp;
  handleClick?: () => void;
  handleChange?: (value: string) => void;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  defaultValue?: string;
  value?: string;
  handleOnEnterPress?: () => void;
  error?: string;
  size?: "small";
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  placeholderCenter?: boolean;
  valueCenter?: boolean;
}

const MaterialTextfieldBase: FC<MaterialTextfieldBaseProps> = (
  props: MaterialTextfieldBaseProps
) => {
  const {
    color = "secondary",
    defaultValue,
    error,
    fullWidth = true,
    handleChange,
    handleClick,
    label,
    name,
    placeholder,
    startIcon,
    endIcon,
    disabled,
    pattern,
    required,
    size,
    type,
    value = "",
    handleOnEnterPress,
    multiline,
    rows,
    isLoading,
    placeholderCenter,
    valueCenter,
  } = props;

  const classes = useMaterialTextfieldBaseStyles();

  const renderEndIcon = (): React.ReactNode | undefined => {
    // if (type === 'search' && isLoading === true) {
    //   return <CircularProgress size={20} />;
    // }
    return error ? <ErrorOutline color="error" /> : endIcon;
  };

  const renderTextCenter = () => {
    if (valueCenter) {
      return classes.customTextCodeBox;
    } else if (placeholderCenter) {
      return classes.customPlaceHolder;
    } else {
      return classes.customTextfield;
    }
  };

  return (
    <TextField
      variant="filled"
      margin="normal"
      className={renderTextCenter()}
      size={size}
      hiddenLabel
      color={color}
      style={{ marginTop: "5px" }}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder || label}
      name={name}
      type={type}
      value={value}
      onChange={(evt) => {
        let value = evt.target.value;

        if (pattern && value && !pattern.test(value.toString())) {
          evt.target.value = value.slice(0, value.length - 1);
          return;
        }

        handleChange?.(value);
      }}
      InputProps={{
        disableUnderline: true,
        "aria-label": handleClick ? "clickable" : undefined,
        onClick: handleClick,
        startAdornment: startIcon,
        endAdornment: renderEndIcon(),
      }}
      inputProps={{ pattern }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleOnEnterPress?.();
        }
      }}
      error={!!error}
      helperText={error}
    />
  );
};

export default MaterialTextfieldBase;
