import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { ReactComponent as KabriolLogo } from "@assets/images/icon-logo.svg";
import { isMobile } from "@utils/isMobile.utils";

interface DescriptionOfPageProps {
  title: string;
  description: string;
  logo?: boolean;
  isDescriptionPadding?: boolean;
  code?: boolean;
}

const DescriptionOfPage: FC<DescriptionOfPageProps> = (props) => {
  const { title, description, logo, isDescriptionPadding } = props;
  const isMobiles = isMobile();

  return (
    <Grid item style={{ textAlign: "center" }}>
      {logo && <KabriolLogo style={{ marginBottom: "39px" }} />}
      <Typography
        variant={isMobiles ? "h2" : "h1"}
        fontWeight={700}
        color="primary"
      >
        {title}
      </Typography>
      <Typography
        fontSize={isMobiles ? 14 : 16}
        fontWeight={400}
        style={{
          marginTop: "14px",
          padding: isDescriptionPadding ? "0px 50px" : 0,
        }}
      >
        {description}
      </Typography>
    </Grid>
  );
};

export default DescriptionOfPage;
