import React from "react";
import { ReactComponent as KabriolLogo } from "@assets/images/icon-login-header.svg";
import { isMobile } from "@utils/isMobile.utils";
import { Button, Grid, Typography } from "@mui/material";

const NullScreen = () => {
  const isMobiles = isMobile();
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ height: "100vh", width: "100%" }}
    >
      <Grid item>
        <KabriolLogo style={{ margin: "auto" }} />
      </Grid>
      <Grid item>
        <Typography
          variant={isMobiles ? "h2" : "h1"}
          fontWeight={700}
          color={"primary"}
          style={{
            marginTop: "10px",
            textAlign: "center",
            padding: "0px 10px",
          }}
        >
          Cette page {`n'`}existe pas, veuillez vous diriger vers les pages
          suivantes :
        </Typography>
        <Grid
          item
          container
          spacing={3}
          flexDirection="column"
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          <Grid item>
            <Button
              href="/codebox?codebox=true"
              variant="contained"
              color="primary"
              style={{ width: "auto", display: "flex", alignItems: "center" }}
            >
              Vous avez un code ?
            </Button>
          </Grid>
          <Grid item>
            <Button
              href="/offer?offer=true"
              variant="contained"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Nos offres
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NullScreen;
