import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useModalStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    margin: "0px !important",
    height: "auto",
  },
  root: {
    margin: "0px !important",
  },
  container: {
    justifyContent: "center !important",
  },
}));
