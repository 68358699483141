import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useLoginStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "50px 0px",
  },
  linkPassword: {
    marginRight: "auto",
    textAlign: "right",
    fontSize: "12px",
    color: "#262E3E",
    display: "flex",
    justifyContent: "right",
    width: "100%",
  },
  linkRegister: {
    marginRight: "auto",
    textAlign: "right",
    fontSize: "16px",
    color: "#FA5725",
    fontWeight: "700",
  },
  input: {
    margin: "auto !important",
  },
  linkRegisterPosition: {
    marginTop: "30px !important",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
}));
