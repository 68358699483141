import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { string } from "yup";
import { useCardSubscriptionStyles } from "./cardSubscription.style";

interface cardSubscriptionProps {
  title: string;
  price: string;
  description: string;
  initialCardSelected?: boolean;
  handleClick?: () => void;
  onSelected?: number;
  value?: number;
}

const cardSubscription: FC<cardSubscriptionProps> = (props) => {
  const {
    title,
    price,
    description,
    initialCardSelected,
    handleClick,
    onSelected,
    value,
  } = props;
  const classes = useCardSubscriptionStyles();

  const classSelected = () => {
    if (!initialCardSelected) {
      return classes.container;
    } else if (value === onSelected) {
      return classes.container;
    } else {
      return classes.disabled;
    }
  };

  return (
    <Card className={classSelected()} elevation={8} onClick={handleClick}>
      <CardHeader className={classes.header} title={title} />
      <CardContent>
        <Grid item flexDirection="column" textAlign="center">
          <Typography fontSize={20} fontWeight={700}>
            {price}
          </Typography>
          <Typography fontSize={12}>{description}</Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default cardSubscription;
