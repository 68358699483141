import React, { FormEvent, useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useLoginStyles } from "./login.style";
import { Link } from "react-router-dom";
import MaterialLoginTextfield from "@components/materialTextfield/materialLoginTextfield";
import DescriptionOfPage from "@components/titlePage/descriptionOfPage";
import HeaderUnAuth from "@components/headerUnAuth";
import { isMobile } from "@utils/isMobile.utils";
import { FieldErrors } from "@utils/yup.utils";
import { authQuery, authService } from "@store/auth";
import { useHistory } from "react-router-dom";
import Modal from "@components/modal";
import { LoadingButton } from "@mui/lab";

const LoginScreen = () => {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useLoginStyles();
  const isMobiles = isMobile();
  const [errors, setErrors] = useState<FieldErrors>();
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const _loading = authQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _loading.unsubscribe();
    };
  }, []);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    authService.login(mail, password);
  };

  return (
    <Grid
      container
      xs={12}
      justifyContent="center"
      className={classes.container}
    >
      <HeaderUnAuth />
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Grid
          item
          container
          flexDirection="column"
          alignContent="center"
          rowSpacing={isMobiles ? 4 : 10}
          style={{ padding: isMobiles ? "0 44px" : "44px" }}
        >
          <DescriptionOfPage
            title="Connexion"
            description="Connectez-vous avec votre compte Kabriol pour accéder à la page
              suivante"
          />
          <Grid
            item
            container
            flexDirection="column"
            sm={8}
            md={8}
            lg={4}
            xl={3}
            rowSpacing={isMobiles ? 2 : 3}
            className={classes.input}
          >
            <Grid item>
              <MaterialLoginTextfield
                label="Adresse mail"
                type="email"
                required
                placeholder="Entrez une adresse mail"
                color="primary"
                error={errors?.email}
                value={mail}
                handleChange={setMail}
              />
            </Grid>
            <Grid item>
              <MaterialLoginTextfield
                label="Mot de passe"
                required
                error={errors?.password}
                placeholder="Entrez un mot de passe"
                color="primary"
                value={password}
                handleChange={setPassword}
                type={"password"}
              />
              <Link
                to="/login"
                className={classes.linkPassword}
                onClick={() => setIsOpenModal(true)}
              >
                Mot de passe oublié ?
              </Link>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.buttonContainer}
            >
              Se connecter
            </LoadingButton>
            <Grid item className={classes.linkRegisterPosition}>
              <Typography variant="h6" fontWeight={400}>
                Vous êtes nouveau sur Kabriol ?
              </Typography>
              <Link to="/register" className={classes.linkRegister}>
                Inscrivez-vous ici!
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {isOpenModal && (
        <Modal
          title="Mot de passe oublié"
          description="Pour réinitialiser votre mot de passe, renseigner l'adresse e-mail utilisée lors de votre inscription."
          subDescription="Nous vous enverrons un mail."
          handleClickCloseModal={() => setIsOpenModal(false)}
          isMail
        >
          <MaterialLoginTextfield
            type="email"
            required
            placeholder="Entrez une adresse mail"
            color="primary"
            error={errors?.email}
            value={mail}
            handleChange={setMail}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={() => authService.resetPassword(mail)}
            style={{ marginTop: "30px" }}
            className={classes.buttonContainer}
          >
            Envoyer
          </LoadingButton>
        </Modal>
      )}
    </Grid>
  );
};

export default LoginScreen;
