import Parse from "parse";

export class User extends Parse.User<Parse.Attributes> {
  static className = "_User";

  constructor(attributes: Partial<User>) {
    super(attributes);
  }

  public get objecttId(): string {
    return this.get("objectId");
  }

  public get kabriolPoints(): number {
    return this.get("kabriolPoints");
  }

  public get emailVerified(): boolean {
    return this.get("emailVerified");
  }
  public get updatedAt(): Date {
    return this.get("updatedAt");
  }
  public get authData(): Parse.Object {
    return this.get("authData");
  }
  public get phoneNumber(): number {
    return this.get("phonenumber");
  }
  public get username(): string {
    return this.get("username");
  }
  public get currentSubscription(): Parse.Pointer {
    return this.get("winner_id");
  }

  public get createdAt(): Date {
    return this.get("createdAt");
  }
  public get firstname(): string {
    return this.get("firstname");
  }

  public get password(): string {
    return this.get("password");
  }

  public get email(): string {
    return this.get("email");
  }

  public get subscription(): Parse.Relation {
    return this.get("subscription");
  }

  public get birthday(): Date {
    return this.get("birthday");
  }
}

export function createUser(params: Partial<User>) {
  return {} as User;
}
