import { createTheme } from "@mui/material";
import { Colors } from "./materialColors";
import fonts from "../assets/font/index";

const defaultTheme = createTheme();

const theme = (colors: typeof Colors) =>
  createTheme({
    palette: {
      primary: {
        light: colors.lightPrimary,
        main: colors.primary,
      },
    },
    typography: {
      fontFamily: "Opificio,sans-serif",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `${fonts}
                *{
                    margin: 0;
                }`,
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: 30,
            fontWeight: 800,
          },
          h2: {
            fontSize: 26,
            fontWeight: 800,
          },
          h3: {
            fontSize: 24,
            fontWeight: 800,
          },
          h4: {
            fontSize: 18,
            fontWeight: 400,
          },
          h5: {
            fontSize: 18,
            fontWeight: 300,
          },
          h6: {
            fontSize: 14,
            fontWeight: 800,
          },
          subtitle1: {
            fontSize: 22,
            fontWeight: 800,
          },
          subtitle2: {
            fontSize: 18,
            fontWeight: 800,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontWeight: 700,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: `0 8px 12px 5px #F8F4F3`,
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "contained", color: "primary" },
            style: {
              width: 230,
              height: 59,
              fontSize: 22,
              fontWeight: 700,
              color: "white",
              textTransform: "initial",
              borderRadius: 8,
              alignItems: "end",
            },
          },
        ],
        styleOverrides: {
          root: {
            ":disabled": {
              backgroundColor: Colors.primary,
              opacity: "50%",
              color: "white",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            ".MuiFilledInput-root": {
              borderRadius: 10,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            paddingTop: 10,
            margin: "0 5px",
          },
          paper: {
            boxShadow: "0 8px 12px 5px rgb(0 0 0 / 20%)",
          },
        },
      },
    },
  });

export default theme;
