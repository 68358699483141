import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useRenaultOfferStyles = makeStyles((theme: Theme) => ({
  gridButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  imgStyle: {
    border: "1px solid black",
    borderRadius: "7px",
    margin: "auto",
  },
}));
