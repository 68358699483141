import React, { useState, useEffect } from "react";
import DescriptionOfPage from "@components/titlePage/descriptionOfPage";
import CardSubscription from "@components/card/cardSubscription/cardSubscription";
import { cardSubscriptionData } from "@utils/datacard.utils";
import kids from "@assets/images/header-kids.png";
import { isMobile, isTablet, isUltraWide } from "@utils/isMobile.utils";
import Modal from "@components/modal";
import { useHistory } from "react-router-dom";
import { codeboxService, codeboxQuery } from "@store/codebox/";
import { userOfferStyles } from "./offer.style";
import ButtonPaypal from "@api/paypal/paypal";
import { apiUtils } from "@api/api.utils";
import { apiStripe } from "@api/stripe/stripe";
import GooglePlay from "@assets/images/google_play.png";
import PlayStore from "@assets/images/apple_store.png";
import { Grid, Button, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const OfferScreen = () => {
  const [selected, setSelected] = useState(0);
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [isOpenModalChoicePayment, setIsOpenModalChoicePayment] =
    useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const isMobiles = isMobile();
  const isTablets = isTablet();
  const isUltraWides = isUltraWide();
  const history = useHistory();
  const success = localStorage.getItem("success");
  const cancel = localStorage.getItem("cancel");
  const classes = userOfferStyles();

  useEffect(() => {
    const _loading = codeboxQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _loading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get(success!)) {
      localStorage.removeItem("cancel");
      codeboxService.createUserSubscription().then(() => {
        setIsPaymentValid(true);
        setIsOpenModalResponse(true);
      });
    } else if (query.get(cancel!)) {
      localStorage.removeItem("success");
      setIsPaymentValid(false);
      setIsOpenModalResponse(true);
    }
    history.push("/offer");
  }, []);

  return (
    <Grid container item xs={12} justifyContent="center">
      {!isMobiles && <img src={kids} width="100%" />}
      <Grid item xs={12} style={{ marginTop: "50px" }}>
        <DescriptionOfPage
          logo={isMobiles ? false : true}
          isDescriptionPadding
          title="Les offres Kabriol"
          description="Retrouvez toutes nos offres SANS ABONNEMENT qui vous donnent accès à tout le contenu de l'application."
        />
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        rowSpacing={isMobiles ? 5 : 15}
        style={{ margin: "0px 50px", width: isUltraWides ? "70%" : "100%" }}
      >
        {cardSubscriptionData.map((data, index) => (
          <Grid
            container
            item
            lg={4}
            xl={3}
            key={index}
            spacing={1}
            flexDirection={isMobiles ? "column" : "row"}
            justifyContent="center"
            alignItems="center"
            style={{
              paddingTop: isTablets ? "50px" : "120px",
            }}
          >
            <CardSubscription
              title={data.title}
              price={`${data.price}€`}
              description={data.description}
              value={selected}
              onSelected={index}
              initialCardSelected={isCardSelected}
              handleClick={() => {
                setSelected(index), setIsCardSelected(true);
              }}
            />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          className={classes.gridButton}
          style={{
            marginBottom: isMobiles ? "30px" : "70px",
            paddingTop: isTablets ? "50px" : "120px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!isCardSelected}
            onClick={() => setIsOpenModalChoicePayment(true)}
            className={classes.buttonContainer}
          >
            Valider
          </Button>
        </Grid>
      </Grid>
      {isOpenModalChoicePayment && (
        <Modal
          title="Veuillez sélectionner votre mode de paiement"
          handleClickCloseModal={() => setIsOpenModalChoicePayment(false)}
        >
          <Grid
            item
            container
            spacing={3}
            flexDirection="column"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item>
              <ButtonPaypal
                selected={selected}
                onClick={() => apiUtils.sendItemsInLocalStorage(selected)}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="primary"
                onClick={apiStripe.redirectToCheckout(selected)}
                className={classes.buttonContainer}
              >
                Carte bancaire
              </LoadingButton>
            </Grid>
          </Grid>
        </Modal>
      )}
      {isOpenModalResponse && (
        <Modal
          title={
            isPaymentValid ? "Merci pour votre achat !" : "Paiement non abouti!"
          }
          description={
            isPaymentValid
              ? "Vous pouvez maintenant retrouver tout votre contenu sur votre application Kabriol"
              : "Nous avons rencontré quelques problèmes lors du paiement de votre coffret."
          }
          subDescription={
            isPaymentValid ? undefined : "Veuillez essayer à nouveau!"
          }
          isLogo
          handleClickCloseModal={() => setIsOpenModalResponse(false)}
        >
          {isPaymentValid && (
            <div style={{ marginTop: isMobiles ? "20px" : "30px" }}>
              <LoadingButton href="https://play.google.com/store/apps/details?id=com.kabriol.app">
                <img
                  src={GooglePlay}
                  alt="Logo Play Store"
                  width={150}
                  className={classes.imgStyle}
                />
              </LoadingButton>
              <LoadingButton href="https://apps.apple.com/fr/app/kabriol-en-voiture-les-enfants/id1568991322">
                <img
                  src={PlayStore}
                  alt="Logo App Store"
                  width={150}
                  className={classes.imgStyle}
                />
              </LoadingButton>
            </div>
          )}
        </Modal>
      )}
      {isLoading && (
        <CircularProgress
          color="primary"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      )}
    </Grid>
  );
};

export default OfferScreen;
