import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useMaterialTextfieldBaseStyles = makeStyles((theme: Theme) => ({
  customTextfield: {
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
  customPlaceHolder: {
    "& input::placeholder": {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  customTextCodeBox: {
    "& input": {
      textAlign: "center",
    },
  },
}));
