import { Store, StoreConfig } from "@datorama/akita";
import { User } from "./auth.model";

export interface AuthState {}

const initialState: AuthState = {};

@StoreConfig({
  name: "auth",
  resettable: true,
})
export class AuthStore extends Store<User> {
  constructor() {
    super(initialState);
  }
}

export const authStore = new AuthStore();
