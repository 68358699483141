import React from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import WaveMobile from "@assets/images/wave-mobile.webp";
import { ReactComponent as Logo } from "@assets/images/icon-login-header.svg";
import { useHeaderUnAuthStyles } from "./headerUnAuth.style";
import { isMobile } from "@utils/isMobile.utils";

const HeaderUnAuth = () => {
  const classes = useHeaderUnAuthStyles();
  const isMobiles = isMobile();

  return (
    <Grid container item justifyContent="center" className={classes.container}>
      <Grid item>
        <Logo />
        <Typography
          fontSize={16}
          fontWeight={700}
          style={{ marginTop: "24px" }}
        >
          Bienvenue sur
        </Typography>
        <Typography
          fontSize={38}
          fontWeight={700}
          color="primary"
          fontFamily="Opificio"
        >
          Kabriol
        </Typography>
      </Grid>
      <Grid container item style={{ marginBottom: isMobiles ? "20px" : 0 }}>
        <img src={WaveMobile} width="100%" height="55px" />
      </Grid>
    </Grid>
  );
};

export default HeaderUnAuth;
