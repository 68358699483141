import React, { useState, useEffect } from "react";
import CardSubscription from "@components/card/cardSubscription/cardSubscription";
import renault from "@assets/images/renault.jpg";
import { isMobile, isTablet, isUltraWide } from "@utils/isMobile.utils";
import Modal from "@components/modal";
import { useHistory } from "react-router-dom";
import { codeboxService, codeboxQuery } from "@store/codebox/";
import { useRenaultOfferStyles } from "./renaultOffer.style";
import GooglePlay from "@assets/images/google_play.png";
import PlayStore from "@assets/images/apple_store.png";
import { Grid, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as KabriolRenaultLogo } from "@assets/images/logo-renault-kabriol.svg";
import { RoutesPath } from "@utils/enums/routes.utils";

const RenaultOfferScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState(false);
  const [isOfferActive, setIsOfferActive] = useState(false);
  const isMobiles = isMobile();
  const isTablets = isTablet();
  const isUltraWides = isUltraWide();
  const classes = useRenaultOfferStyles();
  const history = useHistory();

  useEffect(() => {
    const _loading = codeboxQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _loading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("start")) {
      setOffer(true);
      setIsOfferActive(true);
    } else if (query.get("end")) {
      setOffer(true);
      setIsOfferActive(false);
    }
    history.push(`/${RoutesPath.RENAULTOFFER}`);
  }, []);

  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();
    codeboxService.renaultFreeOffer().then(() => {
      setTimeout(() => {
        if (localStorage.getItem("renaultOfferDate") === "start") {
          history.push(`/${RoutesPath.RENAULTOFFER}?start=true`);
          window.location.reload();
        } else if (localStorage.getItem("renaultOfferDate") === "end") {
          history.push(`/${RoutesPath.RENAULTOFFER}?end=true`);
          window.location.reload();
        }
      }, 1000);
    });
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      {!isMobiles && <img src={renault} width="100%" height="auto" />}
      <Grid item xs={12} style={{ marginTop: "50px" }}>
        <Grid
          item
          style={{
            textAlign: "center",
            padding: isMobiles ? "0px 25px" : "8px 0px",
          }}
        >
          <Typography
            variant={isMobiles ? "h2" : "h1"}
            fontWeight={700}
            color="primary"
          >
            {` L'`}offre
          </Typography>
          <KabriolRenaultLogo
            width={isMobiles ? "250px" : "auto"}
            style={{ margin: "20px auto" }}
          />
          <Typography
            fontSize={isMobiles ? 14 : 16}
            fontWeight={400}
            style={{ width: isMobiles ? "100%" : "70%", margin: "auto" }}
          >
            Retrouvez notre offre exceptionnelle en partenariat avec Renault,
            qui vous permet {`d'`}accéder aux contenus de {`l'`}application{" "}
            depuis votre smartphone mais aussi directement dans votre voiture!
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        rowSpacing={isMobiles ? 5 : 15}
        style={{ margin: "0px 50px", width: isUltraWides ? "70%" : "100%" }}
      >
        <Grid
          container
          item
          lg={4}
          xl={3}
          spacing={1}
          flexDirection={isMobiles ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          style={{
            paddingTop: isTablets ? "50px" : "80px",
          }}
        >
          <CardSubscription
            title="3 mois gratuits avec Renault"
            price="Gratuit"
            description={`et sans engagement${"\n"} et sur toutes les plateformes`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.gridButton}
          style={{
            paddingTop: isTablets ? "70px" : "100px",
          }}
        >
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.buttonContainer}
          >
            Valider
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            style={{
              textAlign: "center",
            }}
          >
            <Typography
              fontSize={isMobiles ? 14 : 16}
              fontWeight={400}
              style={{ marginBottom: isMobiles ? "10px" : "30px" }}
            >
              Retrouvez également {`l'`}application Kabriol directement sur
              votre smartphone!
            </Typography>
            <div style={{ margin: isMobiles ? "20px 0px" : "30px 0px" }}>
              <LoadingButton href="https://play.google.com/store/apps/details?id=com.kabriol.app">
                <img
                  src={GooglePlay}
                  alt="Logo Play Store"
                  width={150}
                  className={classes.imgStyle}
                  style={{ marginRight: isMobiles ? 0 : "15px" }}
                />
              </LoadingButton>
              <LoadingButton href="https://apps.apple.com/fr/app/kabriol-en-voiture-les-enfants/id1568991322">
                <img
                  src={PlayStore}
                  alt="Logo App Store"
                  width={150}
                  className={classes.imgStyle}
                />
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
        {offer && (
          <Modal
            title={isOfferActive ? "Offre activée!" : "Offre terminée!"}
            description={
              isOfferActive
                ? "Votre offre de 3 mois offerts est maintenant active."
                : "Votre offre de 3 mois offerts est maintenant terminée."
            }
            subDescription={
              isOfferActive
                ? "Vous n'avez plus qu'à télécharger l'application sur votre voiture et vous connecter!"
                : "Pour poursuivre votre parcours, veuillez choisir une offre :"
            }
            handleClickCloseModal={() => setOffer(false)}
          >
            {isOfferActive ? (
              <Grid
                item
                container
                spacing={3}
                flexDirection="column"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <div style={{ marginTop: isMobiles ? "5px" : "15px" }}>
                  <Typography fontSize={isMobiles ? 14 : 16} fontWeight={400}>
                    Vous pouvez aussi profiter de cette offre sur votre
                    smartphone
                  </Typography>
                </div>
              </Grid>
            ) : (
              <Grid
                item
                container
                spacing={3}
                flexDirection="column"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Grid item>
                  <Button
                    href="/offer?offer=true"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Nos offres
                  </Button>
                </Grid>
              </Grid>
            )}
          </Modal>
        )}
      </Grid>
    </Grid>
  );
};

export default RenaultOfferScreen;
