import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { akitaDevtools, persistState } from "@datorama/akita";
import Parse from "parse";
import { User } from "./store/auth/auth.model";
require("dotenv").config();
Parse.Object.registerSubclass("_User", User);

Parse.serverURL = "https://parseapi.back4app.com/";

Parse.initialize(
  "TDBYrkHdpp6wTyRqD9v2h7uqlN7I0pRuPyxmMP1m",
  "NV4YXhEW65dlw8fSjWYM3VDFfbBlfrWXefxOJ5Vo"
);
persistState();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
akitaDevtools();
reportWebVitals();
