import React, { FC } from "react";
import { ReactComponent as KabriolLogo } from "@assets/images/icon-login-header.svg";
import { isMobile } from "@utils/isMobile.utils";
import { Grid, CircularProgress } from "@mui/material";

interface SplashProps {
  isLoading: boolean;
}

const Splash: FC<SplashProps> = (props) => {
  const { isLoading } = props;

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ height: "100vh", width: "100%" }}
    >
      <Grid item>
        <KabriolLogo style={{ margin: "auto" }} />
      </Grid>
      <Grid item>
        <CircularProgress
          color="primary"
          style={{
            margin: "auto",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Splash;
