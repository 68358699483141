import { alpha } from "@mui/material";

export const Colors = {
  /** Primary */
  primary: "#FA5725", //Red Orange
  lightPrimary: alpha("#FA5725", 0.5),
  extraLightPrimary: alpha("#F4A084", 0.06),
  /** Other */
  buttonShadow: "rgba(0, 0, 0, 0.04)",
};
