import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCodeboxStyles = makeStyles((theme: Theme) => ({
  imgStyle: {
    border: "1px solid black",
    borderRadius: "7px",
    margin: "auto",
  },
}));
