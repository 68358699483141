import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Colors } from "@style/materialColors";

export const useRenaultRegisterStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "50px 0px",
  },
  itemContainer: {
    padding: "0px 44px !important",
  },
  input: {
    margin: "auto !important",
  },
  linkLogin: {
    marginRight: "auto",
    textAlign: "right",
    fontSize: "16px",
    color: "#FA5725",
    fontWeight: "700",
  },
  linkLoginPosition: {
    marginTop: "30px !important",
    textAlign: "center",
  },
  buttonPosition: {
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },

  specialOffer: {
    color: Colors.primary,
    fontWeight: 700,
    fontSize: "20px",
  },
}));
