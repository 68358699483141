// @ts-ignore
import OpificioBold from "./Opificio/Opificio-Bold.ttf";
// @ts-ignore
import OpificioRegular from "./Opificio/Opificio-Regular.ttf";
// @ts-ignore
import OpificioLight from "./Opificio/Opificio-Light.ttf";

const fonts = `
  @font-face {
    font-family: 'Opificio';
    font-weight: 800;
    src: local('Opificio'), url(${OpificioBold}) format('truetype');
  }
  @font-face {
    font-family: 'Opificio';
    font-weight: 400;
    src: local('Opificio'), url(${OpificioRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Opificio';
    font-weight: 300;
    src: local('Opificio'), url(${OpificioLight}) format('truetype');
  }`;

export default fonts;
