import { cardSubscriptionData } from "@utils/datacard.utils";
import { cardStatusSelected } from "@utils/enums/cardStatus.utils";

export class ApiUtils {
  generateToken(length: number) {
    let a =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
        ""
      );
    let b = [];
    for (let i = 0; i < length; i++) {
      let j = parseInt((Math.random() * (a.length - 1)).toFixed(0));
      b[i] = a[j];
    }
    return b.join("");
  }

  amountStripe = (selected: number) => {
    return {
      price: cardSubscriptionData.find((_, i) => i === selected)
        ?.priceForStripe,
      quantity: cardSubscriptionData.find((_, i) => i === selected)?.quantity,
    };
  };

  amountPayPal = (selected: number) => {
    return {
      description: cardSubscriptionData.find((_, i) => i === selected)
        ?.description!,
      amount: {
        currency_code: "EUR",
        value: cardSubscriptionData.find((_, i) => i === selected)?.price!,
      },
    };
  };

  sendItemsInLocalStorage = (selected: number) => {
    localStorage.setItem("cardStatus", cardStatusSelected[selected]);
    localStorage.setItem("success", this.generateToken(32));
    localStorage.setItem("cancel", this.generateToken(32));
  };

  sendItemsCodeboxInLocalStorage = () => {
    localStorage.setItem("codeSuccess", this.generateToken(32));
  };
}

export const apiUtils = new ApiUtils();
