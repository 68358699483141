enum MediaTypes {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
  WideScreen = "wideScreen",
  UltraWide = "ultraWide",
}

export enum MediaDimensions {
  Mobile = 300,
  Tablet = 768,
  Desktop = 1030,
  WideScreen = 1200,
  UltraWide = 2500,
}

export const breakpoints = {
  [MediaTypes.Mobile]: `${MediaDimensions.Mobile}px`,
  [MediaTypes.Tablet]: `${MediaDimensions.Tablet}px`,
  [MediaTypes.Desktop]: `${MediaDimensions.Desktop}px`,
  [MediaTypes.WideScreen]: `${MediaDimensions.WideScreen}px`,
  [MediaTypes.UltraWide]: `${MediaDimensions.UltraWide}px`,
};

export const device = {
  [MediaTypes.Mobile]: `screen and (min-width: ${
    breakpoints[MediaTypes.Mobile]
  })`,
  [MediaTypes.Tablet]: `screen and (min-width: ${
    breakpoints[MediaTypes.Tablet]
  })`,
  [MediaTypes.Desktop]: `screen and (min-width: ${
    breakpoints[MediaTypes.Desktop]
  })`,
  [MediaTypes.WideScreen]: `screen and (min-width: ${
    breakpoints[MediaTypes.WideScreen]
  })`,
  [MediaTypes.UltraWide]: `screen and (min-width: ${
    breakpoints[MediaTypes.UltraWide]
  })`,
};
