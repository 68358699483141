import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCardSubscriptionStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: "302px",
    cursor: "pointer !important",
  },
  disabled: {
    minWidth: "302px",
    opacity: 0.5,
    cursor: "pointer !important",
  },
  header: {
    background: "#FA5725",
    fontSize: "20px !important",
    color: "white",
    textAlign: "center",
  },
}));
