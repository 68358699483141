export const cardSubscriptionData = [
  {
    title: "Mois",
    price: "4.99",
    description: "",
    priceForStripe: process.env.REACT_APP_STRIPE_PRICE_MONTH_PROD,
    quantity: 1,
  },
  {
    title: "Trimestre",
    price: "7.99",
    description: "Soit 30% d’économie",
    priceForStripe: process.env.REACT_APP_STRIPE_PRICE_TRIMESTER_PROD,
    quantity: 1,
  },
  {
    title: "Année",
    price: "19.99",
    description: "Soit 60% d’économie",
    priceForStripe: process.env.REACT_APP_STRIPE_PRICE_YEAR_PROD,
    quantity: 1,
  },
];
