import { Query } from "@datorama/akita";
import { CodeboxStore, codeboxStore } from "./codebox.store";
import { CodeboxModel } from "./codebox.model";

export class CodeboxQuery extends Query<CodeboxModel> {
  constructor(protected store: CodeboxStore) {
    super(store);
  }
}

export const codeboxQuery = new CodeboxQuery(codeboxStore);
