import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarUtilsConfigurator } from "./utils/snackbar.utils";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/lab";
import { BrowserRouter } from "react-router-dom";
import { Colors } from "./style/materialColors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DateAdapter from "@mui/lab/AdapterDateFns";
import Routes from "./screens";
import theme from "./style/materialTheme";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "@stripe/stripe-js";

function App() {
  return (
    <ThemeProvider theme={theme(Colors)}>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD!,
          currency: "EUR",
        }}
      >
        <LocalizationProvider dateAdapter={DateAdapter}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            iconVariant={{
              success: (
                <CheckCircleOutlineIcon
                  color="inherit"
                  style={{ marginRight: 10 }}
                />
              ),
              error: (
                <HighlightOffIcon color="inherit" style={{ marginRight: 10 }} />
              ),
              info: (
                <ErrorOutlineIcon color="inherit" style={{ marginRight: 10 }} />
              ),
            }}
          >
            <CssBaseline />
            <SnackbarUtilsConfigurator />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </PayPalScriptProvider>
    </ThemeProvider>
  );
}

export default App;
