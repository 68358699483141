import { Store, StoreConfig } from "@datorama/akita";
import { CodeboxModel } from "./codebox.model";

const initialState = (): CodeboxModel => {
  return {};
};

@StoreConfig({
  name: "codebox",
  resettable: true,
})
export class CodeboxStore extends Store<CodeboxModel> {
  constructor() {
    super(initialState());
  }
}

export const codeboxStore = new CodeboxStore();
